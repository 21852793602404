import _ from 'lodash';
import styled from 'styled-components';
// import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDesktop } from 'src/utils/device';
// ? TODO:  <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} page={KEY_ADS_PAGE.CONTENT} />

/* -------------------------------- KEY_TYPE -------------------------------- */
export const KEY_ADS_POSITION = {
  BILLBOARD: 'billboard',
  STICKY_1: 'sticky-1',
  STICKY_2: 'sticky-2',
  STICKY_3: 'sticky-3',
  INARTICLE_1: 'inarticle-1',
  INARTICLE_2: 'inarticle-2',
  INARTICLE_3: 'inarticle-3',
  INARTICLE_4: 'inarticle-4',
  INARTICLE_5: 'inarticle-5',
  INARTICLE_6: 'inarticle-6',
  POSTARTICLE: 'postarticle',
  AD1X1: 'ad1x1'
};

export const KEY_ADS_PAGE = {
  HOMEPAGE: 'homepage',
  CATEGORY: 'category',
  CONTENT: 'content',
  TAG: 'tag',
  SEARCH: 'search'
};

export const Ads = ({
  slotId = '',
  sizeMobile = [],
  sizeDesktop = [],
  slotDefine = '',
  className = '',
  sizeMaps = ''
}) => {
  if (_.isEmpty(slotId) || _.isEmpty(slotDefine)) return null;
  const isDesktop = useDesktop();
  const slotSize = isDesktop ? sizeDesktop : sizeMobile;

  useEffect(() => {
    window.googletag.cmd.push(() => {
      window.googletag
        .defineSlot(slotDefine, slotSize, slotId)
        .addService(window.googletag.pubads())
        .setCollapseEmptyDiv(true, true);
      window.googletag.pubads().enableSingleRequest();
      window.googletag.enableServices();

      window.googletag.cmd.push(function () {
        window.googletag.display(slotId);
      });
    });
  }, []);
  // console.log('slotId', slotId);
  return (
    <AdsWrapper className={className}>
      {/* {className} */}
      <div id={slotId} />
    </AdsWrapper>
  );
};

const AdsWrapper = styled.div`
  &.ads-billboard {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    min-height: 300px;
    iframe {
      margin: auto;
    }
  }
  &.ads-ad1x1 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &.ads-sticky-1,
  &.ads-sticky-2,
  &.ads-sticky-3 {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  &.ads-postarticle {
    position: sticky;
    bottom: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  &.ads-inarticle-1,
  &.ads-inarticle-2,
  &.ads-inarticle-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 30px auto;
    overflow: hidden;
    img,
    iframe {
      max-width: 100%;
      display: block;
      margin: auto;
    }
  }
`;

// const ModifyAdsSection = (page, data) => {
//   const router = useRouter();
//   const { query, asPath } = router;
//   // content --> page
//   // {cid: 'news', id: '1016703'} --> query
//   // content:business" -->_arr -->  ['content', 'business']
//   const _arr = `${data?.section}`.split(':');
//   const pathLength = asPath?.split('/').length;

//   //   console.log('pathLengthaa', pathLength === 3 ? `${page}:${query?.cid}:${query?.id}` : `${page}:${query?.cid}:${query?.sid}:${query?.id}`);
//   //   console.log('pathLength', pathLength === 3 ? `${page}:${asPath?.split('/')[1]}` : `${page}:${asPath?.split('/')[1]}:${asPath?.split('/')[2]}`);
//   //   console.log('aa', `${page}:${asPath?.split('/')[1]}:${asPath?.split('/')[2]}`);
//   switch (page) {
//     case KEY_ADS_PAGE.CATEGORY:
//       // เงื่อนไข sub-category
//       if (query?.sub && _.includes(_arr, `${query?.sub}`)) {
//         return `${KEY_ADS_PAGE.CATEGORY}:${query?.cid}:${query?.sub}`;
//       }
//       // เงื่อนไข category
//       return `${KEY_ADS_PAGE.CATEGORY}:${query?.cid}`;
//     case KEY_ADS_PAGE.CONTENT:
//       // เงื่อนไขเฉพาะ category_id
//       if (_.includes(_arr, `${query?.id}`)) {
//         // console.log(
//         //   'KEY_ADS_PAGE.CONTENT',
//         //   data?.section.split(':'),
//         //   _.includes(_arr, `${query?.id}`)
//         // );
//         return pathLength === 3 ? `${page}:${asPath?.split('/')[1]}:${query?.id}` : `${page}:${asPath?.split('/')[1]}:${asPath?.split('/')[2]}:${query?.id}`; // content:news
//       }
//       // , `${page}:${query?.cid}`);
//       // เงื่อนไข category_id
//       return pathLength === 3 ? `${page}:${asPath?.split('/')[1]}` : `${page}:${asPath?.split('/')[1]}:${asPath?.split('/')[2]}`; // content:news
//     default:
//       // เงื่อนไข homepage
//       return page;
//   }
// };

const DynamicAds = ({ position = '', data = [] }) => {
  // console.log('DynamicAds', data);
  // console.log('page', page);
  // console.log('position', position);
  const _adsArray = !_.isEmpty(data)
    ? data
    : [];

  // data.filter((_data) => _data?.section === ModifyAdsSection(page, _data))
  // console.log('_adsArray', page, position, _adsArray[0]);
  // เช็คตำแหน่ง ADS
  const _adsSlot = _adsArray.find((_data) => _data?.position === position);
  if (!_.isEmpty(_adsSlot)) {
    // console.log('DynamicAds', _adsSlot, _adsSlot?.sizeDesktop);
    // console.log('_adsSlot', _adsSlot);

    return (
      <>
        <Ads
          className={`ads-${position}`}
          slotDefine={_adsSlot?.slotDefine}
          slotId={_adsSlot?.slotId}
          sizeDesktop={_adsSlot?.sizeDesktop}
          sizeMobile={_adsSlot?.sizeMobile}
        />
      </>
    );
  }
  return null;
};
export default DynamicAds;

// :: Default ::
// <Ads
//    className='ads'
//    slotDefine='/231599934,12270527/springnews/homepage/billboard'
//    slotId='div-gpt-ad-1622722566999-0'
//    slotSize=[[300, 250], [336, 280]] />
