import Cookies from 'js-cookie';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { NEXT_PUBLIC_LOGIN_COOKIE_DATE } from 'src/constants';

function useUser() {
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await fetch('/api/auth/me', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok || response.statusText === 'No Content') {
          setResult({});
        } else {
          const res = await response.json();
          Cookies.set('login-data', res, { secure: true, expires: Number(NEXT_PUBLIC_LOGIN_COOKIE_DATE) }); //, { expires: 1, sameSite: 'strict', secure: true, httpOnly: true }
          setResult({ user: res });
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    const isLogin = Cookies.get('login-state');
    const cookies = Cookies.get('login-data');
    if (!_.isEmpty(isLogin) && !_.isEmpty(cookies)) {
      const cook = Cookies.get('login-data');
      setResult({ user: cook });
    } else if (!_.isEmpty(isLogin) && _.isEmpty(cookies)) {
      getUser();
    }
  }, []);

  return result;
}

export default useUser;
